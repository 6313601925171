export const UserClickedAddToCalendarEvent = ({
  label,
  sessionTitle,
  speakers,
  role,
  level,
  products
}) => {
  return {
    asUserActivity: () => {
      return {
        calendarType: label,
        level,
        products,
        role,
        sessionTitle,
        speakers
      };
    },
    asGTM: () => {
      return {
        event: 'eventTracker',
        eventCategory: 'Add to Calendar',
        eventAction: label,
        eventLabel: sessionTitle,
        eventValue: 0,
        nonInteraction: 0,
        cd3: sessionTitle,
        cd4: role,
        cd5: level,
        cd6: products
      };
    }
  };
};
