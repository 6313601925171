import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from './Dropdown';
import { getCalDropdownListItems } from '../../utils/CalendarUtils';
import isThGoUser from '../../utils/isThGoUser';

const CalendarEvent = ({
  buttonText,
  hide,
  item,
  dropdownClassName,
  variant
}) => {
  // `value` is not currently being used, but this is a meaningful placeholder,
  // where we may want to let the Dropdown know the currently selected option.
  const [value, setValue] = useState(null);
  if (hide) return null;
  return (
    <div className="tlv-calendar-event">
      <Dropdown
        buttonText={buttonText}
        className={dropdownClassName}
        onSelect={v => setValue(v)}
        options={getCalDropdownListItems(item)}
        openItemsNewTab={!isThGoUser()}
        value={value}
        variant={variant}
      />
    </div>
  );
};

CalendarEvent.defaultProps = {
  hide: false,
  variant: 'tertiary'
};

CalendarEvent.propTypes = {
  buttonText: PropTypes.string,
  dropdownClassName: PropTypes.string,
  hide: PropTypes.bool,
  item: PropTypes.shape({}).isRequired,
  variant: PropTypes.string
};

export default CalendarEvent;
