import React from 'react';
import PropTypes from 'prop-types';
import iconLive from '../../assets/images/icon-live.png';

const LiveBadge = ({ className }) => (
  <img
    src={iconLive}
    className={className}
    style={{ height: '28px' }}
    alt="Live session indicator"
  />
);

LiveBadge.defaultProps = {
  className: ''
};

LiveBadge.propTypes = {
  className: PropTypes.string
};

export default LiveBadge;
