// Created from `src/assets/images/icon-add-calendar.svg` to:
// - Modify via CSS
// - Avoid Safari issue where using `<use xlinkHref...>` would not show icon in local development

import React from 'react';

export default () => (
  <svg
    version="1.1"
    id="add-calendar"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 18 18"
    xmlSpace="preserve"
  >
    <g id="Symbols">
      <g id="add-calendar-group" transform="translate(-11.000000, -10.000000)">
        <g id="action">
          <path
            id="add-calendar-path"
            d="M23.5,22.7c0,0.2-0.2,0.4-0.4,0.4h-2.3v2.3c0,0.2-0.2,0.4-0.4,0.4h-0.8c-0.2,0-0.3-0.2-0.3-0.4
			v-2.3H17c-0.2,0-0.3-0.2-0.3-0.4v-0.8c0-0.2,0.1-0.4,0.3-0.4h2.3v-2.3c0-0.2,0.1-0.4,0.3-0.4h0.8c0.2,0,0.4,0.2,0.4,0.4v2.3h2.3
			c0.2,0,0.4,0.2,0.4,0.4V22.7z M27.8,16.6c0.3,0,0.6,0.3,0.6,0.6l0,0v9c0,1-0.8,1.8-1.8,1.8l0,0H13.4c-1,0-1.8-0.8-1.8-1.8l0,0v-9
			c0-0.3,0.3-0.6,0.6-0.6l0,0H27.8z M23.9,10c0.7,0,1.2,0.5,1.2,1.2l0,0v0.6h1.5c1,0,1.8,0.8,1.8,1.8l0,0v0.6
			c0,0.3-0.3,0.6-0.6,0.6l0,0H12.2c-0.3,0-0.6-0.3-0.6-0.6l0,0v-0.6c0-1,0.8-1.8,1.8-1.8l0,0h1.5v-0.6c0-0.7,0.5-1.2,1.2-1.2
			c0.7,0,1.2,0.5,1.2,1.2l0,0v0.6h5.4v-0.6C22.7,10.5,23.2,10,23.9,10z"
          />
        </g>
      </g>
    </g>
  </svg>
);
