import React from 'react';

export default () => (
  <svg id="play" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.5 9.23">
    <path
      id="play-path"
      d="M1.31,0h.1a.76.76,0,0,1,.5.2h0l5.7,3.3.1.1a1.31,1.31,0,0,1-.1,2.2h0L4.81,7.4,2,9l-.1.1A1.32,1.32,0,0,1,0,7.9H0V1.3A1.21,1.21,0,0,1,1.31,0Z"
    />
  </svg>
);
