import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from '../CustomLink';
import LiveCard from '../LiveCard';
import VideosContext from '../../contexts/VideosContext';
import BroadcastingContext from '../../contexts/BroadcastingContext';
import AuthContext from '../../contexts/AuthContext';
import filterLiveScheduleGroups from '../../utils/filterLiveScheduleGroups';
import iconCamera from '../../assets/images/icon-camera.png';
import { VIDEO_STATUS } from '../../constants/videoStatus';

const LiveScheduleHeading = ({ showViewAllButton }) => {
  return (
    <div className="tlv-page__section-heading">
      <img src={iconCamera} alt="" />
      <h2 className="tlv-page__heading tlv-page-heading--icon">
        Live Schedule
      </h2>
      {showViewAllButton && (
        <Link
          to="/schedule"
          className="slds-button slds-button_neutral"
          role="button"
        >
          View All
        </Link>
      )}
    </div>
  );
};

const LiveScheduleContent = React.memo(props => {
  const { ready } = useContext(VideosContext);
  const { broadcasting: isLive } = useContext(BroadcastingContext);
  const { isLoggedIn } = useContext(AuthContext);
  const {
    upcomingVideos,
    currentVideos,
    groupByDays,
    // optional prop for testing
    time = Date.now()
  } = props;
  const filteredUpcoming = filterLiveScheduleGroups({ upcomingVideos, time })({
    filterBy: groupByDays
  });

  if (!ready) {
    return (
      <div className="tlv-live-schedule">
        <article className="tlv-card-schedule tlv-card-schedule--loading slds-card tds-card">
          <div className="slds-spinner_container">
            <div
              role="status"
              className="slds-spinner slds-spinner_medium slds-spinner_brand"
            >
              <span className="slds-assistive-text">Loading</span>
              <div className="slds-spinner__dot-a" />
              <div className="slds-spinner__dot-b" />
            </div>
          </div>
        </article>
      </div>
    );
  }

  if (!currentVideos.length && !filteredUpcoming.length) {
    return <div>No upcoming live sessions.</div>;
  }

  const currentCards = currentVideos.map((video, i) => (
    <LiveCard
      index={i}
      key={video.id}
      video={video}
      status={VIDEO_STATUS.CURRENT}
      isLive={isLive}
      isLoggedIn={isLoggedIn}
    />
  ));
  const upcomingCards = filteredUpcoming.map((video, i) => (
    <LiveCard
      index={i}
      key={video.id}
      video={video}
      status={VIDEO_STATUS.UPCOMING}
      isLive={isLive}
      isLoggedIn={isLoggedIn}
    />
  ));

  const liveCards = [...currentCards, ...upcomingCards];

  return (
    <div className="tlv-live-schedule" data-testid="live-schedule">
      {liveCards}
    </div>
  );
});

LiveScheduleHeading.defaultProps = {
  showViewAllButton: false
};

LiveScheduleHeading.propTypes = {
  showViewAllButton: PropTypes.bool
};

LiveScheduleContent.propTypes = {
  upcomingVideos: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentVideos: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupByDays: PropTypes.string,
  time: PropTypes.number
};

export { LiveScheduleHeading, LiveScheduleContent };
