import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '../CustomLink';
import DatetimeUtils from '../../utils/DatetimeUtils';
import SessionUtils from '../../utils/SessionUtils';
import CalendarEvent from '../CalendarEvent';
import LiveBadge from '../LiveBadge';
import Speaker from '../Speaker';
import GatedContentIndicator from '../GatedContentIndicator';
import isContentGated from '../../utils/isContentGated';
import { VIDEO_STATUS } from '../../constants/videoStatus';

const LiveIcon = () => (
  <div className="tlv-card-schedule__live-icon">
    <LiveBadge />
  </div>
);

const LiveCard = props => {
  const {
    status,
    video,
    video: {
      sessionDescription,
      sessionTitle,
      speakers,
      lengthOfSession,
      datetime,
      slug
    },
    index,
    isLive,
    isLoggedIn
  } = props;

  const { CURRENT, UPCOMING } = VIDEO_STATUS;
  // TODO: update this code if we want to show more than one speaker
  const {
    speakerName,
    imageUrl,
    speakerTitle,
    speakerTBIDProfile
  } = speakers[0];

  const path = `/broadcasts/${slug}`;
  const gatedMessage = {
    [CURRENT]: `Login required`,
    [UPCOMING]: `Login required`
  };
  const isCurrentVideo = status === 'CURRENT';
  const isCurrentBroadcast = index === 0; // we can remove this when we support multi-channel broadcasts
  // in case of overlap, "watch now" button and "live" icon will only show for
  // current broadcast (until we remove `isCurrentBroadcast` above w/
  // multi-channel support)
  const showWatchNow = isCurrentBroadcast && isCurrentVideo;
  const showLiveIcon = showWatchNow && isLive;
  return (
    <article className="tlv-card-schedule slds-card tds-card">
      <div className="tlv-card-schedule__timetable">
        <div className="tlv-card-schedule__date">
          <div
            className="tlv-card-schedule__day"
            data-testid="live-schedule-day"
          >
            {DatetimeUtils.getLocalizedDate(datetime)}
          </div>
          <div
            className="tlv-card-schedule__time"
            data-testid="live-schedule-time"
          >
            {DatetimeUtils.getLocalizedTime(datetime)}
          </div>
          <CalendarEvent
            hide={isCurrentVideo}
            item={video}
            dropdownClassName="tlv-dropdown--responsive"
          />
          {showLiveIcon && <LiveIcon />}
        </div>
        {showWatchNow && (
          <Link
            to={path}
            className="tlv-card-schedule__watch-now slds-button slds-button--brand"
          >
            Watch Now
          </Link>
        )}
      </div>
      <div className="tlv-card-schedule__details">
        {!isLoggedIn && isContentGated({ video, status }) && (
          <GatedContentIndicator
            labelText={gatedMessage[status]}
            className="tlv-card-schedule__gated"
          />
        )}
        <h3
          className="tlv-card-schedule__title tlv-card-schedule__title--no-min-height"
          data-testid="live-schedule-title"
        >
          <Link to={path}>{sessionTitle}</Link>
        </h3>
        <div
          className="tlv-card-schedule__description"
          title={sessionDescription}
        >
          <p
            className={`${!isCurrentVideo ? 'slds-line-clamp' : ''}`}
            data-testid="live-schedule-description"
          >
            {sessionDescription}
          </p>
        </div>
        <div
          className="tlv-card-schedule__speaker"
          data-testid="live-schedule-speaker"
        >
          <Speaker
            imageUrl={imageUrl}
            speakerName={speakerName}
            speakerSubtext={speakerTitle}
            speakerTBIDProfile={speakerTBIDProfile}
          />
          <div
            className="tlv-card-schedule__duration"
            data-testid="live-schedule-duration"
          >
            ~{SessionUtils.getLengthOfSession(lengthOfSession)}
          </div>
        </div>
      </div>
    </article>
  );
};

LiveCard.propTypes = {
  status: PropTypes.oneOf(['UPCOMING', 'CURRENT']).isRequired,
  index: PropTypes.number.isRequired,
  video: PropTypes.shape({
    speakers: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string.isRequired,
        speakerName: PropTypes.string.isRequired,
        speakerTitle: PropTypes.string.isRequired,
        speakerTBIDProfile: PropTypes.string
      })
    ),
    sessionDescription: PropTypes.string,
    sessionTitle: PropTypes.string,
    lengthOfSession: PropTypes.string,
    nextUpcoming: PropTypes.bool,
    datetime: PropTypes.string,
    slug: PropTypes.string
  }).isRequired,
  isLive: PropTypes.bool,
  isLoggedIn: PropTypes.bool
};

export default LiveCard;
