import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import iconLock from '../../assets/images/icon-lock.svg';

const GatedContentIndicator = ({ labelText, className }) => (
  <div className={cx('tlv-gated-content-indicator', className)}>
    <img
      src={iconLock}
      className="tlv-gated-content-indicator__icon"
      alt="Lock icon"
    />
    <span className="tlv-gated-content-indicator__copy">{labelText}</span>
  </div>
);

GatedContentIndicator.propTypes = {
  labelText: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default GatedContentIndicator;
