import React, { useRef, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import sldsUtilityIcon from '../../assets/vendor/slds/icons/utility-sprite/svg/symbols.svg';
import { IconAddCalendar } from '../SvgIcon';
import useHandleClickOutside from '../../hooks/useHandleClickOutside';
import useHandleKeyDown from '../../hooks/useHandleKeyDown';
import keys from '../../constants/keys';
import moveFocus from '../../utils/moveFocus';
import userActivityReducer from '../../store/reducers/userActivityReducer';
import { userClickedAddToCalendar } from '../../store/actions';
import { UserClickedAddToCalendarEvent } from '../../events/userClickedAddToCalendar';

const DropdownTrigger = ({ buttonText, onClick, variant }) => {
  const variantStyle =
    variant === 'brand' ? 'slds-button_brand' : 'tds-button_tertiary';
  return (
    <button
      className={`slds-button slds-button_icon tlv-dropdown-trigger ${variantStyle}`}
      aria-haspopup="true"
      onClick={onClick}
      title="Show Calendar Options"
      type="button"
    >
      <svg
        aria-hidden="true"
        className="slds-m-right--x-small tlv-icon tlv-icon-add-calendar tlv-icon-size-xx-small-plus"
        data-testid="icon-add-to-calendar"
      >
        <IconAddCalendar />
      </svg>
      {buttonText && (
        <span
          className="slds-m-right_x-small"
          data-testid="dropdown-button-text"
        >
          {buttonText}
        </span>
      )}
      <svg
        aria-hidden="true"
        className="slds-icon slds-icon_x-small tlv-icon"
        data-testid="icon-utility-down"
      >
        <use xlinkHref={`${sldsUtilityIcon}#down`} />
      </svg>
      <span className="slds-assistive-text">Add to Calendar</span>
    </button>
  );
};

const ListItem = ({
  icon,
  href,
  label,
  handleClick,
  rel,
  target,
  openItemsNewTab,
  level,
  products,
  role,
  sessionTitle,
  speakers
}) => (
  <li
    className="slds-dropdown__item"
    role="presentation"
    onClick={() =>
      handleClick(label, level, products, role, sessionTitle, speakers)
    }
  >
    <a
      href={href}
      className="tlv-dropdown-item__icon"
      role="menuitem"
      tabIndex={0}
      target={openItemsNewTab ? target : undefined}
      id={label}
      rel={openItemsNewTab ? rel : undefined}
      style={{ backgroundImage: `url(${icon})` }}
    >
      <span className="slds-truncate" title={label}>
        {label}
      </span>
    </a>
  </li>
);
// adding `value` below to give optionality for the dropdown component to know which value is selected
// eslint-disable-next-line no-unused-vars
const Dropdown = ({
  buttonText,
  className,
  // value,
  options,
  onSelect,
  openItemsNewTab,
  variant
}) => {
  const dropdownRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(undefined);

  const handleClose = () => {
    setIsOpen(false);
    setActiveIndex(undefined);
    const triggerButton = dropdownRef?.current.querySelector('button');
    triggerButton.focus();
  };

  const handleClickOutside = e => {
    if (!isOpen || dropdownRef.current.contains(e.target)) {
      return;
    }
    handleClose();
  };
  const [, dispatch] = useReducer(userActivityReducer, {});
  const handleItemClick = (
    label,
    level,
    products,
    role,
    sessionTitle,
    speakers
  ) => {
    onSelect(label);
    const payload = UserClickedAddToCalendarEvent({
      label,
      sessionTitle,
      speakers,
      role,
      level,
      products
    });
    dispatch(userClickedAddToCalendar(payload));
    handleClose();
  };

  const handleSelect = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
    setActiveIndex(undefined);
  };

  const handleKeyDown = e => {
    const { ArrowUp, ArrowDown, Backspace, Delete, Escape, Space, Tab } = keys;
    const closeKeys = [Escape, Delete, Backspace, Tab];
    const dropdown = dropdownRef.current;
    const list = dropdown.parentNode.querySelector('ul');
    const move = moveFocus({
      list,
      index: activeIndex,
      handler: setActiveIndex,
      listItemSelector: 'a'
    });

    if (closeKeys.includes(e.key)) {
      handleClose();
    }
    if (e.key === ArrowUp) {
      e.preventDefault();
      move('up');
    }
    if (e.key === ArrowDown) {
      e.preventDefault();
      move('down');
    }
    if (e.key === Space && activeIndex >= 0) {
      e.preventDefault();
    }
  };

  useHandleClickOutside({ handler: handleClickOutside });
  useHandleKeyDown({
    condition: isOpen,
    handler: handleKeyDown,
    elementRef: dropdownRef
  });

  return (
    <div
      className={`slds-dropdown-trigger slds-dropdown-trigger_click ${
        isOpen ? 'slds-is-open' : ''
      }`}
      ref={dropdownRef}
      data-testid="dropdown-trigger"
    >
      <DropdownTrigger
        buttonText={buttonText}
        isOpen={isOpen}
        onClick={handleSelect}
        variant={variant}
      />
      <div className={cx('slds-dropdown tlv-dropdown', className)}>
        <ul
          className="slds-dropdown__list"
          role="menu"
          aria-label="Calendar Options"
        >
          {options.map(
            ({
              icon,
              href,
              label,
              rel,
              target,
              level,
              products,
              role,
              sessionTitle,
              speakers
            }) => (
              <ListItem
                icon={icon}
                href={href}
                target={target}
                rel={rel}
                label={label}
                key={label}
                handleClick={handleItemClick}
                openItemsNewTab={openItemsNewTab}
                level={level}
                products={products}
                role={role}
                sessionTitle={sessionTitle}
                speakers={speakers}
              />
            )
          )}
        </ul>
      </div>
    </div>
  );
};

ListItem.defaultProps = {
  rel: undefined,
  target: undefined
};

ListItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  href: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.string,
  handleClick: PropTypes.func,
  openItemsNewTab: PropTypes.bool.isRequired,
  level: PropTypes.string,
  products: PropTypes.string,
  role: PropTypes.string,
  sessionTitle: PropTypes.string,
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      speakerName: PropTypes.string.isRequired,
      speakerTitle: PropTypes.string.isRequired,
      speakerTBIDProfile: PropTypes.string
    })
  )
};
DropdownTrigger.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired
};

Dropdown.defaultProps = {
  // value: null,
  openItemsNewTab: true,
  variant: 'tertiary'
};

Dropdown.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  // value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      href: PropTypes.string
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  openItemsNewTab: PropTypes.bool,
  variant: PropTypes.string
};
export default Dropdown;
